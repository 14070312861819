import React, { Component } from 'react';
import Svg, {
    G,
    Path,
    Rect,
    Defs,
    Pattern,
    Use,
    LinearGradient,
    Stop,
    ClipPath,
    Circle,
    Image
} from 'react-native-svg';

class IconPraNikahDesktop extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Svg
                xmlns="http://www.w3.org/2000/svg"
                width={104}
                height={179}
                fill="none"
                {...this.props}
            >
                <Circle cx={45.346} cy={3.613} r={57.773} fill="#fff" fillOpacity={0.1} />
                <Circle
                    cx={23.412}
                    cy={23.412}
                    r={23.412}
                    fill="#fff"
                    fillOpacity={0.1}
                    transform="matrix(1 0 0 -1 33.281 201.025)"
                />
                <Rect width={60} height={60} x={12.369} y={12.702} fill="#fff" rx={12} />
                <G fill="#7367F0" clipPath="url(#a)">
                    <Path d="M30.287 50.063c2.3 0 4.418-.776 6.125-2.067a10.12 10.12 0 0 0 6.124 2.067c5.618 0 10.188-4.57 10.188-10.187 0-5.618-4.57-10.187-10.188-10.187-2.3 0-4.418.775-6.124 2.066a10.12 10.12 0 0 0-6.125-2.066c-5.618 0-10.187 4.57-10.187 10.187 0 5.617 4.57 10.187 10.187 10.187Zm6.125-14.303a7.339 7.339 0 0 1 1.257 4.116c0 1.523-.464 2.94-1.257 4.116a7.339 7.339 0 0 1-1.258-4.116c0-1.523.465-2.94 1.258-4.116Zm6.125-3.267c4.07 0 7.383 3.312 7.383 7.383 0 4.07-3.312 7.382-7.383 7.382a7.34 7.34 0 0 1-4.125-1.263 10.12 10.12 0 0 0 2.062-6.119c0-2.298-.774-4.414-2.062-6.12a7.34 7.34 0 0 1 4.125-1.263Zm-12.25 0a7.34 7.34 0 0 1 4.125 1.263 10.122 10.122 0 0 0-2.062 6.12c0 2.298.774 4.413 2.062 6.12a7.34 7.34 0 0 1-4.125 1.262c-4.071 0-7.383-3.311-7.383-7.382 0-4.07 3.312-7.383 7.383-7.383Z" />
                    <Path d="M36.214 25.662h-.012s-1.55-1.698-2.903-.457c-1.353 1.24-.17 4.097 2.903 5.337l.012.01c3.072-1.24 4.255-4.107 2.903-5.348-1.353-1.24-2.903.458-2.903.458Z" />
                </G>
                <Defs>
                    <ClipPath id="a">
                        <Path fill="#fff" d="M20.099 21.095h32.624v32.624H20.099z" />
                    </ClipPath>
                </Defs>
            </Svg>
        )
    }
}


export default IconPraNikahDesktop;