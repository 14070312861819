import React, { Component } from 'react';
import Svg, {
    G,
    Path,
    Rect,
    Defs,
    Pattern,
    Use,
    LinearGradient,
    Stop,
    ClipPath,
    Circle,
    Image
} from 'react-native-svg';

class IconMenikah extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Svg
                xmlns="http://www.w3.org/2000/svg"
                width={107}
                height={135}
                fill="none"
                {...this.props}
            >
                <Circle cx={47.033} cy={3.921} r={60.035} fill="#fff" fillOpacity={0.1} />
                <Circle
                    cx={24.245}
                    cy={24.245}
                    r={24.245}
                    fill="#fff"
                    fillOpacity={0.1}
                    transform="matrix(1 0 0 -1 34.333 157.472)"
                />
                <Rect
                    width={50.799}
                    height={50.799}
                    x={7.777}
                    y={7.385}
                    fill="#fff"
                    rx={7}
                />
                <G fill="#DC4776" clipPath="url(#a)">
                    <Path d="M42.698 31.624c-.186-3.136-3.348-3.043-3.348-3.043s-3.065 2.858-6.322 0c0 0-.188-.185-.839 0-.647.183-1.019.585-1.48.526H30.7c-.46.059-.833-.343-1.48-.526-.651-.185-.839 0-.839 0-3.257 2.859-6.322 0-6.322 0s-3.162-.093-3.348 3.043c-.186 3.136 3.098 9.866 11.993 9.684 8.895.182 12.179-6.549 11.993-9.684Zm-6.796 2.64c-.078.893-.465 1.728-1.055 2.407a13.808 13.808 0 0 1-4.14 3.237v.014l-.012-.006c-.005.001-.009.004-.013.006v-.014a13.7 13.7 0 0 1-4.14-3.237c-.589-.679-.976-1.514-1.054-2.407-.082-.934.15-2.04 1.406-2.732 2.367-1.304 3.66.833 3.8 1.083.14-.25 1.433-2.387 3.8-1.083 1.255.692 1.488 1.799 1.406 2.732h.002ZM25.036 27.52c2.285 0 4.138-1.837 4.138-4.104 0-2.267-1.853-4.104-4.138-4.104-2.286 0-4.139 1.837-4.139 4.104 0 2.267 1.853 4.104 4.139 4.104ZM41.39 26.424c-.68-.482-1.394-1.392-1.575-1.63.154-.432.24-.896.24-1.379 0-2.267-1.852-4.104-4.138-4.104-2.286 0-4.138 1.837-4.138 4.104 0 .618.138 1.204.385 1.73-.315.38-.814.936-1.296 1.278-.683.484 1.929.976 3.11.618a4.15 4.15 0 0 0 1.94.477c.773 0 1.497-.21 2.116-.577 1.003.535 4.095.003 3.358-.52l-.002.003Z" />
                </G>
                <Defs>
                    <ClipPath id="a">
                        <Path fill="#fff" d="M18.705 19.312h24v22h-24z" />
                    </ClipPath>
                </Defs>
            </Svg>
        )
    }
}


export default IconMenikah;