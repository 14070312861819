import React, { Component } from 'react';
import Svg, {
    G,
    Path,
    Rect,
    Defs,
    Pattern,
    Use,
    LinearGradient,
    Stop,
    ClipPath,
    Circle,
    Image,
    Ellipse
} from 'react-native-svg';

class IconBacaBermutu extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Svg
                xmlns="http://www.w3.org/2000/svg"
                width={107}
                height={135}
                fill="none"
                {...this.props}
            >
                <Rect
                    width={238.985}
                    height={108.525}
                    x={0.851}
                    y={0.242}
                    fill="url(#a)"
                    rx={10}
                />
                <Circle cx={24.909} cy={2.829} r={44.839} fill="#fff" fillOpacity={0.1} />
                <Ellipse
                    cx={32.904}
                    cy={33.481}
                    fill="#fff"
                    fillOpacity={0.1}
                    rx={32.904}
                    ry={33.481}
                    transform="matrix(1 0 0 -1 187.882 141.093)"
                />
                <Rect
                    width={50.799}
                    height={50.799}
                    x={11.241}
                    y={29.105}
                    fill="#fff"
                    rx={7}
                />
                <G fill="#FF8300" clipPath="url(#b)">
                    <Path
                        fillRule="evenodd"
                        d="M29.967 59.33h-5.5c-1.297 0-1.945 0-2.348-.403-.402-.403-.402-1.05-.402-2.347V44.205c0-1.297 0-1.945.402-2.347.403-.403 1.051-.403 2.348-.403h1.375c3.889 0 5.833 0 7.042 1.208 1.208 1.208 1.208 3.153 1.208 7.042v13.75a4.125 4.125 0 0 0-4.125-4.125Zm-1.274-12.438a.865.865 0 0 0-1.578 0l-.558 1.175a.883.883 0 0 1-.662.5l-1.247.189c-.722.109-1.01 1.03-.488 1.56l.902.914c.208.21.302.513.253.81l-.213 1.291c-.123.748.632 1.317 1.277.965l1.115-.61a.85.85 0 0 1 .82 0l1.115.61c.645.352 1.4-.217 1.276-.965l-.213-1.291a.937.937 0 0 1 .253-.81l.903-.914c.522-.53.234-1.451-.488-1.56l-1.247-.189a.883.883 0 0 1-.663-.5l-.557-1.175Z"
                        clipRule="evenodd"
                    />
                    <Path d="M43.717 59.33h-4.125a4.125 4.125 0 0 0-4.125 4.125v-13.75c0-3.89 0-5.834 1.208-7.042 1.208-1.208 3.153-1.208 7.042-1.208 1.296 0 1.944 0 2.347.403.403.402.403 1.05.403 2.347V56.58c0 1.296 0 1.944-.403 2.347s-1.05.403-2.347.403Z" />
                </G>
                <Defs>
                    <LinearGradient
                        id="a"
                        x1={120.343}
                        x2={120.343}
                        y1={0.242}
                        y2={108.767}
                        gradientUnits="userSpaceOnUse"
                    >
                        <Stop stopColor="#FF8300" />
                        <Stop offset={1} stopColor="#FF992C" />
                    </LinearGradient>
                    <ClipPath id="b">
                        <Path fill="#fff" d="M17.592 35.455H55.69v38.099h-38.1z" />
                    </ClipPath>
                </Defs>
            </Svg>
        )
    }
}


export default IconBacaBermutu;