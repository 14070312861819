import React, { Component } from 'react';
import Svg, {
    G,
    Path,
    Rect,
    Defs,
    Pattern,
    Use,
    LinearGradient,
    Stop,
    ClipPath,
    Circle,
    Image
} from 'react-native-svg';

class IconPraNikah extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Svg
                xmlns="http://www.w3.org/2000/svg"
                width={107}
                height={135}
                fill="none"
                {...this.props}
            >
                <Circle cx={46.346} cy={3.921} r={60.035} fill="#fff" fillOpacity={0.1} />
                <Circle
                    cx={24.245}
                    cy={24.245}
                    r={24.245}
                    fill="#fff"
                    fillOpacity={0.1}
                    transform="matrix(1 0 0 -1 33.645 157.472)"
                />
                <Rect
                    width={50.799}
                    height={50.799}
                    x={7.091}
                    y={7.385}
                    fill="#fff"
                    rx={7}
                />
                <G fill="#7367F0" clipPath="url(#a)">
                    <Path d="M25.137 40.398a8.066 8.066 0 0 0 4.882-1.647 8.064 8.064 0 0 0 4.88 1.647c4.478 0 8.12-3.642 8.12-8.118 0-4.478-3.642-8.12-8.12-8.12a8.067 8.067 0 0 0-4.88 1.648 8.065 8.065 0 0 0-4.882-1.647c-4.477 0-8.118 3.641-8.118 8.119 0 4.476 3.641 8.118 8.118 8.118Zm4.881-11.399a5.848 5.848 0 0 1 1.003 3.28c0 1.214-.37 2.343-1.003 3.281a5.848 5.848 0 0 1-1.002-3.28c0-1.214.37-2.343 1.003-3.28Zm4.882-2.603a5.89 5.89 0 0 1 5.884 5.884 5.89 5.89 0 0 1-5.884 5.883 5.85 5.85 0 0 1-3.287-1.007 8.065 8.065 0 0 0 1.643-4.876 8.066 8.066 0 0 0-1.643-4.878 5.85 5.85 0 0 1 3.287-1.006Zm-9.763 0a5.85 5.85 0 0 1 3.288 1.006 8.067 8.067 0 0 0-1.644 4.878c0 1.83.617 3.517 1.644 4.876a5.85 5.85 0 0 1-3.288 1.007 5.89 5.89 0 0 1-5.883-5.883 5.89 5.89 0 0 1 5.883-5.884Z" />
                    <Path d="M29.86 20.952h-.008s-1.236-1.353-2.314-.365-.135 3.265 2.314 4.254l.009.008c2.448-.988 3.392-3.274 2.314-4.262-1.079-.988-2.314.365-2.314.365Z" />
                </G>
                <Defs>
                    <ClipPath id="a">
                        <Path fill="#fff" d="M17.019 17.312h26v26h-26z" />
                    </ClipPath>
                </Defs>
            </Svg>
        )
    }
}


export default IconPraNikah;