import React, { Component } from 'react';
import Svg, {
    G,
    Path,
    Rect,
    Defs,
    Pattern,
    Use,
    LinearGradient,
    Stop,
    ClipPath,
    Circle,
    Image
} from 'react-native-svg';

class IconGoldenAgeDesktop extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Svg
                xmlns="http://www.w3.org/2000/svg"
                width={107}
                height={179}
                fill="none"
                {...this.props}
            >
                <Circle cx={45.076} cy={3.812} r={57.973} fill="#fff" fillOpacity={0.1} />
                <Circle
                    cx={23.412}
                    cy={23.412}
                    r={23.412}
                    fill="#fff"
                    fillOpacity={0.1}
                    transform="matrix(1 0 0 -1 32.813 202.257)"
                />
                <Rect
                    width={60}
                    height={60}
                    x={10.299}
                    y={11.071}
                    fill="#fff"
                    rx={9.144}
                />
                <G fill="#F4AF33" clipPath="url(#a)">
                    <Path d="M46.622 31.085c-.483.047-.929.271-1.287.594-5.235 4.674-10.683 5.787-10.975 5.845-.293-.058-5.74-1.17-10.976-5.845-.358-.323-.804-.547-1.286-.594-.94-.09-2.216.41-1.982 3.902.366 5.429 8.321 16.825 14.23 17.67h.028c5.908-.852 13.864-12.248 14.23-17.677.234-3.493-1.042-3.993-1.982-3.903v.008Z" />
                    <Path d="M34.362 33.357c3.937 0 7.129-3.163 7.129-7.066s-3.192-7.066-7.13-7.066c-3.937 0-7.129 3.163-7.129 7.066 0 3.902 3.192 7.066 7.13 7.066Z" />
                </G>
                <Defs>
                    <ClipPath id="a">
                        <Path fill="#fff" d="M20.088 19.225h28.546v33.44H20.088z" />
                    </ClipPath>
                </Defs>
            </Svg>
        )
    }
}


export default IconGoldenAgeDesktop;