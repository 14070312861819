import React, { Component } from 'react';
import { StyleSheet, View, Pressable, TextInput, FlatList, Image } from 'react-native';
import { Typography, Avatar, Skeleton, CardActionArea, Button } from '@mui/material';
import LoadingPage from '../components/LoadingPage';
import WarningDialog from '../components/WarningDialog';
import LoadingDialog from '../components/LoadingDialog';
import { LogoPusnas } from "../assets/Icons";
import { NavHomeIcon, NavNewsIcon, NavRackIcon, NavProfileIcon, NavSearchIcon } from '../assets/NavIcons';
import { primary, color_text, sub_text } from '../constants/colors';
import SearchIcon from '../assets/images/SearchIcon';
import { executeGet, executePost, removeCredentials, executeMultipartPost, truncateText, saveLocalParameters } from '../utilities/Utilities';
import {
    code_ok, code_empty, code_error, code_auth_error, homepage, homepage_banner,
    homepageContentType, homepageCategories, notificationCheck, updateFBToken, updateAvatar, newsList,
    borrowingList, bookReturn, readBorrowedContent
} from '../constants/api';
import * as Command from '../constants/Commands';

import Header from '../components/Header';
import DesktopHomepageBanners from '../components/DesktopHomepageBanners';
import DesktopHomepageContentType from '../components/DesktopHomepageContentType';
import DesktopHomepageContentCategories from '../components/DesktopHomepageContentCategories';
import DesktopSplitRecomNews from '../components/DesktopSplitRecomNews';
import DesktopHomepageContent from '../components/DesktopHomepageContent';
import DesktopProfile from './DesktopProfile';
import DesktopSearchPage from './DesktopSearchPage';
import SubLogo from '../assets/SubLogo';
import EmptySearchPage from '../components/EmptySearchPage';
import MainMenuDesktop from '../components/homepageDesktop/MainMenuDesktop'
import MenuNewsPilihanDesktop from '../components/homepageDesktop/MenuNewsPilihanDesktop'
import MenuKoleksiSekolahDesktop from '../components/homepageDesktop/MenuKoleksiSekolahDesktop';
import MenuKoleksiPerguruanTinggiDesktop from '../components/homepageDesktop/MenuKoleksiPerguruanTinggiDesktop';
import MenuKoleksiBSEDesktop from '../components/homepageDesktop/MenuKoleksiBSEDesktop';
import MenuKoleksiDigitalDesktopNew from '../components/homepageDesktop/MenuKoleksiDigitalDesktopNew';
import MenuYoutube from '../components/homepage/MenuYoutube';
import MenuYoutubeDesktop from '../components/homepageDesktop/MenuYoutubeDesktop';
import MenuSaranaKomunikasiDesktop from '../components/homepageDesktop/MenuSaranaKomunikasiDesktop';
import MenuKoleksiEdukasiDesktop from '../components/homepageDesktop/MenuKoleksiEdukasiDesktop';
import MenuKoleksiInteroperabilitasDesktop from '../components/homepageDesktop/MenuKoleksiInteroperabilitasDesktop';
import MenuPromosiInformasiDesktop from '../components/homepageDesktop/MenuPromosiInformasiDesktop';
import MenuStatistikDesktop from '../components/homepageDesktop/MenuStatistikDesktop';
import RakDigital from '../components/homepageDesktop/RakDigital';
import MenuAudiobookDesktop from '../components/homepageDesktop/MenuAudiobookDesktop';
import MenuTutorialEdukasiDesktop from '../components/homepageDesktop/MenuTutorialEdukasiDesktop';
import MenuYoutubeUtama from '../components/homepageDesktop/MenuYoutubeUtama'
import LogoKeluarga from '../assets/logo_literasikeluarga.png';
import * as Identifier from "../constants/PageIdentifiers";
import MenuIntegrasiDesktop from '../components/homepageDesktop/MenuIntegrasiDesktop';
import MusikKeluargaDesktop from '../components/homepageDesktop/MusikKeluargaDesktop';
import IconPraNikah from '../assets/images/IconPraNikah';
import IconMenikah from '../assets/images/IconMenikah';
import IconGoldenAge from '../assets/images/IconGoldenAge';
import IconGameEdukasi from '../assets/images/IconGameEdukasi';
import IconMusikKeluarga from '../assets/images/IconMusikKeluarga';
import IconLembagaKementrian from '../assets/images/IconLembagaKementrian';
import IconPerpusnasPress from '../assets/images/IconPerpusnasPress';
import IconBacaBermutu from '../assets/images/IconBacaBermutu';
import IconPraNikahDesktop from '../assets/images/IconPraNikahDekstop';
import IconMenikahDesktop from '../assets/images/IconMenikahDesktop';
import IconGoldenAgeDesktop from '../assets/images/IconGoldenAgeDesktop';
import IconGameEdukasiDesktop from '../assets/images/IconGameEdukasiDesktop';
import IconMusikKeluargaDesktop from '../assets/images/IconMusikKeluargaDesktop';
import IconLembagaKementrianDesktop from '../assets/images/IconLembagaKementrianDesktop';

var _is_mounted = false;
const h_banner = "banner";
const h_content = "content";
const h_content_type = "content-types";
const h_categories = "categories";
const h_authors = "authors";
const h_navigation = "main-menu";
const h_recommended = "recommended";
const h_news = "news";
var is_news_rendered = false;
var _first_run = true;
class Main extends Component {
    //ANCHOR - Constructor
    constructor(props) {
        super(props)
        this.state = {
            showLoading: true,
            showLoadingDialog: false,
            loadingDialogMessage: "",
            showAlert: false,
            alertMessage: "",
            selectedMenu: "home",
            searchFocus: false,
            keyword: this.props.route.params.keyword,
            photo: "",
            fullname: "-",
            homepage_data: [
                { "code": "HMP-0000", "title": "Video Untukmu", "type": "youtubeUtama", "sort_no": "1" },
                { "code": "HMP-00X2", "title": "Koleksi Sekolah/Madrasah", "type": "koleksiSekolah", "sort_no": "1" },
                { "code": "HMP-00X3", "title": "Koleksi Perpustakaan Perguruan Tinggi", "type": "koleksiPT", "sort_no": "1" },
                { "code": "HMP-00X5", "title": "Koleksi Digital", "type": "koleksiDigital", "sort_no": "1" },
                { "code": "HMP-0003", "title": "Koleksi Tutorial Edukasi", "type": "kontenEdukasi", "sort_no": "1" },
                { "code": "HMP-0004", "title": "Rak Pinjam", "type": "rakDigital", "sort_no": "1" },
                { "code": "HMP-00M3", "title": "Musik Keluarga", "type": "musikKeluarga", "sort_no": "1" },
                { "code": "HMP-00X9", "title": "Koleksi Interoperabilitas ", "type": "kontenInteroperabilitas", "sort_no": "1" },
                { "code": "HMP-0007", "title": "Berita Pilihan", "type": "beritaPilihan", "sort_no": "1" },
                { "code": "HMP-00X1", "title": "Pengembangan Dan Pembinaan P3SMPT", "type": "mainMenu", "sort_no": "1" },
                // {"code":"HMP-00X8","title":"Statistik Koleksi","type":"statistik","sort_no":"1"}, 
                { "code": "HMP-00X4", "title": "Koleksi BSE", "type": "koleksiBSE", "sort_no": "1" },
                { "code": "HMP-0001", "title": "Promosi & Informasi", "type": "banner", "sort_no": "1" },
                { "code": "HMP-0002", "title": "Sarana Komunikasi", "type": "saranaKomunikasi", "sort_no": "1" },
                { "code": "HMP-0005", "title": "Youtube", "type": "youtube", "sort_no": "1" }
            ],
            // homepage_data : [
            //     {"code":"HMP-00X1","title":"Akses Utama","type":"mainMenu","sort_no":"1"}, 
            //     {"code":"HMP-0007","title":"Berita Pilihan","type":"beritaPilihan","sort_no":"1"},
            //     {"code":"HMP-00X2","title":"Koleksi Perpustakaan Sekolah","type":"koleksiSekolah","sort_no":"1"}, 
            //     {"code":"HMP-00X3","title":"Koleksi Perpustakaan Perguruan Tinggi","type":"koleksiPT","sort_no":"1"}, 
            //     {"code":"HMP-00X4","title":"Koleksi BSE","type":"koleksiBSE","sort_no":"1"}, 
            //     {"code":"HMP-00X5","title":"Koleksi Digital","type":"koleksiDigital","sort_no":"1"}, 
            //     {"code":"HMP-0001","title":"Promosi & Informasi","type":"banner","sort_no":"1"},
            //     {"code":"HMP-0002","title":"Sarana Komunikasi","type":"saranaKomunikasi","sort_no":"1"},
            //     {"code":"HMP-0003","title":"Konten Edukasi","type":"kontenEdukasi","sort_no":"1"},
            //     {"code":"HMP-0005","title":"Youtube","type":"youtube","sort_no":"1"}
            // ],
            news_data: [],
            rack_data: [],
            container_width: 0,
            card_width: 0,
            card_height: 0
        }

        this.handleNavigationClick = this.handleNavigationClick.bind(this);
        this.onSearchInputFocus = this.onSearchInputFocus.bind(this);
        this.onSearchInputBlur = this.onSearchInputBlur.bind(this);
        this.handleSearchPress = this.handleSearchPress.bind(this);
        this.handleSearchTextChange = this.handleSearchTextChange.bind(this);
        this.handleWarningDialogClick = this.handleWarningDialogClick.bind(this);

        this.getHomepage = this.getHomepage.bind(this);
        this.getNews = this.getNews.bind(this);
        this.getRack = this.getRack.bind(this);

        this.renderItem = this.renderItem.bind(this);
        this.renderNews = this.renderNews.bind(this);
        this.renderRack = this.renderRack.bind(this);

        this.onLayoutContainerChange = this.onLayoutContainerChange.bind(this);
        this.navigateNewsDetail = this.navigateNewsDetail.bind(this);

        this.onImageError = this.onImageError.bind(this);
        this.handleBookReturn = this.handleBookReturn.bind(this);
        this.handleReadFromRack = this.handleReadFromRack.bind(this);
        this.readFromRack = this.readFromRack.bind(this);

        this.searchRef = React.createRef();

        this.saveLocalParams = this.saveLocalParams.bind(this);

    }

    componentDidMount = () => {
        _is_mounted = true;
        // console.log("keyword main = " + this.props.route.params.keyword);

        this.saveLocalParams();

        let photo;
        if (this.props.route.params.gender == "L") {
            photo = "https://bintangpusnas.perpusnas.go.id/images/avatar/userL.png";
        } else {
            photo = "https://bintangpusnas.perpusnas.go.id/images/avatar/userP.png";
        }
        this.setState({
            fullname: this.props.route.params.fullname,
            photo: this.props.route.params.photo,
        }, () => {
            this.getHomepage();

        })
        window.addEventListener("message", (event) => {
            if (event.data.command == Command.DISPATCH_MENU) {
                this.handleNavigationClick(event.data.menu);
            }

            if (event.data.hasOwnProperty("id")) {
                if (event.data.id == "sign_out") {

                    removeCredentials(() => {
                        //console.log("LOGOUT NIH = " + JSON.stringify(this.props.route.params));
                        localStorage.clear();
                        document.cookie = "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                        //window.location.reload(true)

                        this.props.navigation.reset({
                            index: 0,
                            routes: [{ name: "LoginDesktop", params: this.props.route.params.options }]
                        })
                    });
                }
            }
        })
        // window.electron.receiveMessage(Command.NAVIGATE_MENU, (params)=>{
        //     this.handleNavigationClick(params.menu);
        // });
    }

    saveLocalParams = async () => {
        //console.log(JSON.stringify(this.props.route.params));
        let obj = this.props.route.params;
        obj.credential = global.defaultHeaders;
        await saveLocalParameters(obj);
    }

    componentWillUnmount = () => {
        _is_mounted = false;
        window.removeEventListener("message", null);

    }

    handleNavigationClick = (menu) => {
        if (_is_mounted) {
            if (menu != "home") {
                is_news_rendered = false;
            }

            if (!this.state.showLoading) {
                this.setState({
                    selectedMenu: menu,
                }, () => {
                    if (menu == 'news') {
                        this.getNews();
                    } else if (menu == 'rack') {
                        this.getRack();
                    }
                })
            }
        }
    }
    onSearchInputFocus = (e) => {
        this.setState({
            searchFocus: true
        })
    }
    onSearchInputBlur = () => {
        this.setState({
            searchFocus: false
        })
    }

    handleSearchTextChange = (text) => {
        if (_is_mounted) {
            this.setState({
                keyword: text
            })
        }
    }
    //ANCHOR - Handle Search
    handleSearchPress = (e) => {
        let key = e.nativeEvent.key;
        if (key == "Enter") {
            //console.log(this.state.keyword);
            if (this.state.selectedMenu != "search") {
                this.setState({
                    selectedMenu: "search",
                    keyword: this.state.keyword
                }, () => {
                    this.searchRef.current.startSearching();
                })
            } else {
                this.setState({
                    keyword: this.state.keyword
                }, () => {
                    this.searchRef.current.startSearching();
                })

            }
        }
    }
    handleWarningDialogClick = () => {
        this.setState({
            showAlert: false
        })
    }

    getHomepage = async () => {
        //console.log("GET HOMEPAGE");
        const response = await executeGet(homepage);
        if (response.code == code_ok) {
            //window.electron.sendMessage(Command.CONSOLE_LOG, JSON.stringify(response)); 
            console.log('data = ' + JSON.stringify(response))
            console.log('data = ' + JSON.stringify(response.data))
            if (response.data.length > 0) {
                this.setState({
                    showLoading: false,
                    // homepage_data : response.data
                }, () => {
                    //this.getNews();
                });
            }
        } else {
            if (response.code == code_auth_error) {
                window.postMessage({ id: "sign_out" })

            } else {
                this.setState({
                    showLoading: false,
                    showAlert: true,
                    alertMessage: response.msg
                })
            }
        }
    }

    gotoLiterasiKeluarga = async (code, title) => {
        var nomorKTP = "";
        let params = { code: code, title: title, origins: "CONTENT_SECTION" };
        if (window.localStorage.getItem('ktp') == null) {
            this.props.navigation.navigate("InputKTPDesktop", params);
        } else {
            nomorKTP = window.localStorage.getItem('ktp');
            //validasi KTP
            if (!isNaN(+nomorKTP)) {
                if (nomorKTP.length != 16) {
                    this.props.navigation.navigate("InputKTPDesktop", params);
                } else {
                    //3576014403910003
                    var umurValid = true;
                    const batasUmur = 18;
                    const currYear = Number(new Date().getFullYear().toString().substr(-2));
                    const batasLahir = currYear - batasUmur;
                    console.log("Tahun Ini: " + currYear)

                    let tahun = nomorKTP.substr(10, 2);
                    if (Number(tahun) < currYear) {
                        //ini kelahiran 2023 s/d 2000
                        if (Number(tahun) > batasLahir) {
                            umurValid = false;
                            this.props.navigation.navigate("InputKTPDesktop", params);
                        }
                    }
                    if (umurValid == true) {
                        this.props.navigation.navigate("DesktopSectionDetail", params);
                    }

                }
            } else {
                this.props.navigation.navigate("InputKTPDesktop", params);
            }
        }
        //let params = { code : code, title : title, origins : "CONTENT_SECTION"}; 
        //this.props.navigation.navigate("SectionDetail", params);

    }

    getNews = async () => {
        //console.log("GET NEWS");
        let formData = new FormData();
        formData.append("page", 1);
        formData.append("limit", 48);
        this.setState({
            showLoading: true,
            news_data: []
        })
        const response = await executePost(newsList, formData);
        if (response.code == code_ok) {

            if (_is_mounted) {
                if (parseInt(response.show) > 0) {
                    let show = parseInt(response.show);
                    let total = parseInt(response.total);
                    let data = [...this.state.news_data, ...response.data];
                    let mod = parseInt(response.show) % 4;
                    if (mod > 0) {
                        let add_factor = 4 - mod;
                        for (let i = 0; i < add_factor; i++) {
                            data.push({ dummy: i });
                        }
                    }
                    this.setState({
                        showLoading: false,
                        news_data: data
                    }, () => {
                        //console.log(JSON.stringify(this.state.news_data));
                    })
                } else {
                    this.setState({
                        showLoading: false
                    })
                }
            }

        } else {
            if (response.code == code_auth_error) {
                //TODO: Force Logout
                window.postMessage({ id: "sign_out" })
            } else {
                this.setState({
                    showLoading: false,
                    showAlert: true,
                    alertMessage: response.msg
                })
            }
        }
    }

    getRack = async () => {
        this.setState({
            showLoading: true
        })
        const response = await executeGet(borrowingList);
        if (response.code == code_ok) {
            let total = parseInt(response.total);
            if (total > 0) {
                let data = [...response.data];
                let mod = total % 2;
                if (mod > 0) {
                    let add_factor = 2 - mod;
                    for (let i = 0; i < add_factor; i++) {
                        data.push({ dummy: i });
                    }
                }
                this.setState({
                    showLoading: false,
                    rack_data: data
                })
            } else {
                //== Empty Rack ==//
                this.setState({
                    showLoading: false,
                    rack_data: []
                })

            }
        } else {
            if (response.code == code_auth_error) {
                window.postMessage({ id: "sign_out" })
            } else {
                this.setState({
                    showLoading: false,
                    showAlert: true,
                    alertMessage: response.msg
                })
            }
        }
    }

    renderItem = ({ item, index, separators }) => {

        if (item.type == 'mainMenu') {
            if (dummy == 1) {

            } else {
                return (
                    <MainMenuDesktop
                        code={item.code}
                        title={item.title}
                        navigation={this.props.navigation}
                    />
                )
            }
        } else if (item.type == "youtubeUtama") {
            return (
                <>
                    <MenuYoutubeUtama
                        code={item.code}
                        title={item.title}
                        navigation={this.props.navigation}
                    />
                    {
                        //ANCHOR - KOLEKSI BINTANG PUSNAS
                    }
                    <View style={{ width: "100%", paddingLeft: 147, paddingRight: 147, marginBottom: 5 }}>
                        <View style={{ padding: 16, borderRadius: 10, boxShadow: "0px 0.9131946563720703px 2.047769784927368px 0px rgba(0, 0, 0, 0.07), 0px 2.194533586502075px 4.921075344085693px 0px rgba(0, 0, 0, 0.0503), 0px 9.82798px 22.0385px rgba(0, 0, 0, 0.035), 0px 5.50948px 12.3546px rgba(0, 0, 0, 0.0417275), 0px 2.92604px 6.56143px rgba(0, 0, 0, 0.0503198), 0px 1.21759px 2.73036px rgba(0, 0, 0, 0.07)" }}>
                            <View style={{ display: "flex", flexDirection: "row", flex: 1, alignItems: "center", paddingTop: 8, paddingBottom: 8, marginBottom: 0, marginLeft: 4, marginRight: 2, marginTop: 0 }}>
                                <View style={{ display: "flex", flex: 0.6, alignItems: 'center' }}>
                                    <img src={LogoKeluarga} alt="literasi keluarga" style={{ maxWidth: '400px' }} />
                                </View>
                                <View style={{ display: "flex", flex: 0.4, flexDirection: "column" }}>
                                    <View style={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', marginBottom: 0, marginLeft: 8, marginRight: 2, marginTop: 6 }}>
                                        <Button
                                            onClick={() => {
                                                let params = { code: "HMP-PRA", title: "Koleksi Pra Nikah", origins: "CONTENT_SECTION" };
                                                this.props.navigation.navigate("DesktopSectionDetail", params);
                                            }}
                                            style={{ backgroundImage: 'linear-gradient(180deg, #7367F0 0%, #7B72D9 100%)', display: "flex", marginRight: 12, flexDirection: "row", overflow: "hidden", marginBottom: 12, paddingBottom: 8, paddingTop: 8, width: "29vw", height: 177, display: "flex", borderRadius: 10, boxShadow: "0px 0.9131946563720703px 2.047769784927368px 0px rgba(0, 0, 0, 0.07), 0px 2.194533586502075px 4.921075344085693px 0px rgba(0, 0, 0, 0.0503), 0px 9.82798px 22.0385px rgba(0, 0, 0, 0.035), 0px 5.50948px 12.3546px rgba(0, 0, 0, 0.0417275), 0px 2.92604px 6.56143px rgba(0, 0, 0, 0.0503198), 0px 1.21759px 2.73036px rgba(0, 0, 0, 0.07)" }}>
                                            <IconPraNikahDesktop style={{ position: 'absolute', top: 0, left: 0 }} />
                                            <Typography
                                                style={{ position: 'absolute', bottom: '15px' }}
                                                fontSize={16}
                                                fontWeight={"600"}
                                                textAlign={"center"}
                                                color={"white"}
                                                textTransform={"none"}
                                            >
                                                {"Pra Nikah"}
                                            </Typography>
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                let params = { code: "HMP-NIKAH", title: "Koleksi Nikah", origins: "CONTENT_SECTION" };
                                                this.props.navigation.navigate("DesktopSectionDetail", params);
                                            }}
                                            style={{ backgroundImage: 'linear-gradient(180deg, #DC4776 0%, #F1608E 100%)', display: "flex", marginRight: 12, flexDirection: "row", overflow: "hidden", marginBottom: 12, paddingBottom: 8, paddingTop: 8, width: "29vw", height: 177, display: "flex", flexDirection: "row", borderRadius: 10, boxShadow: "0px 0.9131946563720703px 2.047769784927368px 0px rgba(0, 0, 0, 0.07), 0px 2.194533586502075px 4.921075344085693px 0px rgba(0, 0, 0, 0.0503), 0px 9.82798px 22.0385px rgba(0, 0, 0, 0.035), 0px 5.50948px 12.3546px rgba(0, 0, 0, 0.0417275), 0px 2.92604px 6.56143px rgba(0, 0, 0, 0.0503198), 0px 1.21759px 2.73036px rgba(0, 0, 0, 0.07)" }}>
                                            <IconMenikahDesktop width={107} height={179} style={{ position: 'absolute', top: 0, left: 0 }} />
                                            <Typography
                                                style={{ position: 'absolute', bottom: '15px' }}
                                                fontSize={16}
                                                fontWeight={"600"}
                                                textAlign={"center"}
                                                color={"white"}
                                                textTransform={"none"}
                                            >
                                                {"Menikah"}
                                            </Typography>
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                let params = { code: "HMP-GOLD", title: "Koleksi Golden Age", origins: "CONTENT_SECTION" };
                                                this.props.navigation.navigate("DesktopSectionDetail", params);
                                            }}
                                            style={{ backgroundImage: 'linear-gradient(180deg, #F4AF33 0%, #FFC254 100%)', display: "flex", marginRight: 12, flexDirection: "row", overflow: "hidden", marginBottom: 12, paddingBottom: 8, paddingTop: 8, width: "29vw", height: 177, display: "flex", flexDirection: "row", borderRadius: 10, boxShadow: "0px 0.9131946563720703px 2.047769784927368px 0px rgba(0, 0, 0, 0.07), 0px 2.194533586502075px 4.921075344085693px 0px rgba(0, 0, 0, 0.0503), 0px 9.82798px 22.0385px rgba(0, 0, 0, 0.035), 0px 5.50948px 12.3546px rgba(0, 0, 0, 0.0417275), 0px 2.92604px 6.56143px rgba(0, 0, 0, 0.0503198), 0px 1.21759px 2.73036px rgba(0, 0, 0, 0.07)" }}>
                                            <IconGoldenAgeDesktop style={{ position: 'absolute', top: 0, left: 0 }} />
                                            <Typography
                                                style={{ position: 'absolute', bottom: '15px' }}
                                                fontSize={16}
                                                fontWeight={"600"}
                                                textAlign={"center"}
                                                color={"white"}
                                                textTransform={"none"}
                                            >
                                                {"Golden Age"}
                                            </Typography>
                                        </Button>
                                    </View>
                                    <View style={{ display: "flex", flexDirection: "row", marginBottom: 0, marginLeft: 8, marginRight: 2 }}>
                                        <Button
                                            onClick={() => {
                                                // let params = { code: "HMP-Edukasi", title: "Permainan Edukasi", origins: "CONTENT_SECTION" };
                                                // this.props.navigation.navigate("SectionGames", params);
                                                this.props.navigation.navigate("DesktopSectionGames", { identifier : Identifier.MAIN })
                                            }}
                                            style={{ backgroundImage: 'linear-gradient(180deg, #0194F3 0%, #1DA6FF 100%)', display: "flex", marginRight: 12, flexDirection: "row", overflow: "hidden", marginBottom: 12, paddingBottom: 8, paddingTop: 8, width: "29vw", height: 177, display: "flex", flexDirection: "row", flex: 0.5, borderRadius: 10, boxShadow: "0px 0.9131946563720703px 2.047769784927368px 0px rgba(0, 0, 0, 0.07), 0px 2.194533586502075px 4.921075344085693px 0px rgba(0, 0, 0, 0.0503), 0px 9.82798px 22.0385px rgba(0, 0, 0, 0.035), 0px 5.50948px 12.3546px rgba(0, 0, 0, 0.0417275), 0px 2.92604px 6.56143px rgba(0, 0, 0, 0.0503198), 0px 1.21759px 2.73036px rgba(0, 0, 0, 0.07)" }}>
                                            <IconGameEdukasiDesktop style={{ position: 'absolute', top: 0, left: 0 }} />
                                            <Typography
                                                style={{ position: 'absolute', bottom: '15px', paddingLeft: 4, paddingRight: 4 }}
                                                fontSize={16}
                                                fontWeight={"600"}
                                                textAlign={"center"}
                                                color={"white"}
                                                textTransform={"none"}
                                                lineHeight={1}
                                            >
                                                {"Permainan Edukasi"}
                                            </Typography>
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                this.props.navigation.navigate("DesktopSectionMusik", { identifier : Identifier.MAIN })
                                            }}
                                            style={{ backgroundImage: 'linear-gradient(180deg, #12D7E4 0%, #0EC8DB 100%)', display: "flex", marginRight: 12, flexDirection: "row", overflow: "hidden", marginBottom: 12, paddingBottom: 8, paddingTop: 8, width: "29vw", height: 177, display: "flex", flexDirection: "row", flex: 0.5, borderRadius: 10, boxShadow: "0px 0.9131946563720703px 2.047769784927368px 0px rgba(0, 0, 0, 0.07), 0px 2.194533586502075px 4.921075344085693px 0px rgba(0, 0, 0, 0.0503), 0px 9.82798px 22.0385px rgba(0, 0, 0, 0.035), 0px 5.50948px 12.3546px rgba(0, 0, 0, 0.0417275), 0px 2.92604px 6.56143px rgba(0, 0, 0, 0.0503198), 0px 1.21759px 2.73036px rgba(0, 0, 0, 0.07)" }}>
                                            <IconMusikKeluargaDesktop style={{ position: 'absolute', top: 0, left: 0 }} />
                                            <Typography
                                                style={{ position: 'absolute', bottom: '15px', paddingLeft: 4, paddingRight: 4 }}
                                                fontSize={16}
                                                fontWeight={"600"}
                                                textAlign={"center"}
                                                color={"white"}
                                                textTransform={"none"}
                                                lineHeight={1}
                                            >
                                                {"Musik Keluarga"}
                                            </Typography>
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                let params = { code: "HMP-Lokal", title: "Kementrian Lembaha", origins: "CONTENT_SECTION" };
                                                this.props.navigation.navigate("DesktopSectionKementrian", params);
                                            }}
                                            style={{ backgroundImage: 'linear-gradient(180deg, #50CD89 0%, #5CDE97 100%)', display: "flex", marginRight: 12, flexDirection: "row", overflow: "hidden", marginBottom: 12, paddingBottom: 8, paddingTop: 8, width: "29vw", height: 177, display: "flex", flexDirection: "row", flex: 0.5, borderRadius: 10, boxShadow: "0px 0.9131946563720703px 2.047769784927368px 0px rgba(0, 0, 0, 0.07), 0px 2.194533586502075px 4.921075344085693px 0px rgba(0, 0, 0, 0.0503), 0px 9.82798px 22.0385px rgba(0, 0, 0, 0.035), 0px 5.50948px 12.3546px rgba(0, 0, 0, 0.0417275), 0px 2.92604px 6.56143px rgba(0, 0, 0, 0.0503198), 0px 1.21759px 2.73036px rgba(0, 0, 0, 0.07)" }}>
                                            <IconLembagaKementrianDesktop style={{ position: 'absolute', top: 0, left: 0 }} />
                                            <Typography
                                                style={{ position: 'absolute', bottom: '15px', paddingLeft: 4, paddingRight: 4 }}
                                                fontSize={16}
                                                fontWeight={"600"}
                                                textAlign={"center"}
                                                color={"white"}
                                                textTransform={"none"}
                                                lineHeight={1}
                                            >
                                                {"Kolaborasi Kementerian & Lembaga"}
                                            </Typography>
                                        </Button>

                                    </View>
                                    <View style={{ display: "flex", flexDirection: "row", marginBottom: 0, marginLeft: 8, marginRight: 2 }}>
                                        <Button
                                            onClick={() => {
                                                let params = { code: "HMP-Lokal", title: "Perpusnas Pres", origins: "CONTENT_SECTION", tipe_lokal: 2 };
                                                this.props.navigation.navigate("DesktopSectionDetail", params);
                                            }}
                                            style={{ backgroundImage: 'linear-gradient(180deg, #A3D513 0%, #B3E71D 100%)', display: "flex", marginRight: 12, flexDirection: "row", overflow: "hidden", marginBottom: 12, paddingBottom: 8, paddingTop: 8, width: "29vw", height: 120, display: "flex", flexDirection: "row", borderRadius: 10, boxShadow: "0px 0.9131946563720703px 2.047769784927368px 0px rgba(0, 0, 0, 0.07), 0px 2.194533586502075px 4.921075344085693px 0px rgba(0, 0, 0, 0.0503), 0px 9.82798px 22.0385px rgba(0, 0, 0, 0.035), 0px 5.50948px 12.3546px rgba(0, 0, 0, 0.0417275), 0px 2.92604px 6.56143px rgba(0, 0, 0, 0.0503198), 0px 1.21759px 2.73036px rgba(0, 0, 0, 0.07)" }}>
                                            <IconPerpusnasPress style={{ position: 'absolute', top: 0, left: 0 }} />
                                            <Typography
                                                style={{ position: 'absolute', bottom: '15px' }}
                                                fontSize={16}
                                                fontWeight={"600"}
                                                textAlign={"center"}
                                                color={"white"}
                                                textTransform={"none"}
                                                lineHeight={1}
                                            >
                                                {"Perpusnas Pres"}
                                            </Typography>
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                let params = { code: "HMP-Lokal", title: "Bacaan Bermutu Anak Indonesia", origins: "CONTENT_SECTION", tipe_lokal: 1 };
                                                this.props.navigation.navigate("DesktopSectionDetail", params);
                                            }}
                                            style={{ backgroundImage: 'linear-gradient(180deg, #FF8300 0%, #FF992C 100%)', display: "flex", marginRight: 12, flexDirection: "row", overflow: "hidden", marginBottom: 12, paddingBottom: 8, paddingTop: 8, width: "58vw", height: 120, display: "flex", flexDirection: "row", flexGrow: 1, borderRadius: 10, boxShadow: "0px 0.9131946563720703px 2.047769784927368px 0px rgba(0, 0, 0, 0.07), 0px 2.194533586502075px 4.921075344085693px 0px rgba(0, 0, 0, 0.0503), 0px 9.82798px 22.0385px rgba(0, 0, 0, 0.035), 0px 5.50948px 12.3546px rgba(0, 0, 0, 0.0417275), 0px 2.92604px 6.56143px rgba(0, 0, 0, 0.0503198), 0px 1.21759px 2.73036px rgba(0, 0, 0, 0.07)" }}>
                                            <IconBacaBermutu style={{ position: 'absolute', top: 0, left: 0 }} />
                                            <Typography
                                                style={{ position: 'absolute', right: '15px', width: '65%' }}
                                                fontSize={16}
                                                fontWeight={"600"}
                                                textAlign={"left"}
                                                color={"white"}
                                                textTransform={"none"}
                                                lineHeight={1}
                                            >
                                                {"Bacaan Bermutu Anak Indonesia"}
                                            </Typography>
                                        </Button>

                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                </>
            )
        } else if (item.type == "koleksiSekolah") {
            return (
                <MenuKoleksiSekolahDesktop
                    code={item.code}
                    title={item.title}
                    navigation={this.props.navigation}
                />
            )
        } else if (item.type == "koleksiPT") {
            return (
                <MenuKoleksiPerguruanTinggiDesktop
                    code={item.code}
                    title={item.title}
                    navigation={this.props.navigation}
                />
            )
        } else if (item.type == "koleksiBSE") {
            // return (
            //     <MenuKoleksiBSEDesktop
            //         code={ item.code }
            //         title={ item.title } 
            //         navigation={ this.props.navigation }
            //     />
            // )
        } else if (item.type == "koleksiDigital") {
            return (
                <>
                    <MenuIntegrasiDesktop
                        code={item.code}
                        title={"Gamifikasi/Permainan Edukasi"}
                        navigation={this.props.navigation}
                    />
                    <MenuKoleksiDigitalDesktopNew
                        code={item.code}
                        title={item.title}
                        navigation={this.props.navigation}
                    />
                </>
            )
        } else if ((item.type == h_banner)) {
            return (
                <DesktopHomepageBanners
                    code={item.code}
                    title={item.title}
                    navigation={this.props.navigation}
                />
            )
        } else if ((item.type == 'saranaKomunikasi')) {
            if (dummy == 1) {

            } else {
                return (
                    <MenuSaranaKomunikasiDesktop
                        code={item.code}
                        title={item.title}
                        navigation={this.props.navigation}
                    />
                )
            }
        } else if ((item.type == 'kontenEdukasi')) {
            if (dummy == 1) {

            } else {
                return (
                    <MenuKoleksiEdukasiDesktop
                        code={item.code}
                        title={item.title}
                        navigation={this.props.navigation}
                    />
                )
            }
        } else if ((item.type == 'kontenInteroperabilitas')) {
            if (dummy == 1) {

            } else {
                return (
                    <MenuKoleksiInteroperabilitasDesktop
                        code={item.code}
                        title={item.title}
                        navigation={this.props.navigation}
                    />
                )
            }
        } else if ((item.type == 'musikKeluarga')) {
            if (dummy == 1) {

            } else {
                return (
                    <MusikKeluargaDesktop
                        code={item.code}
                        title={item.title}
                        navigation={this.props.navigation}
                    />
                )
            }
        } else if ((item.type == 'rakDigital')) {
            if (dummy == 1) {

            } else {
                return (
                    <RakDigital
                        code={item.code}
                        title={item.title}
                        navigation={this.props.navigation}
                    />
                )
            }
        } else if ((item.type == 'beritaPilihan')) {
            if (dummy == 1) {

            } else {
                return (
                    <>
                        <MenuTutorialEdukasiDesktop
                            code={item.code}
                            title={item.title}
                            navigation={this.props.navigation}
                        />
                        <MenuAudiobookDesktop
                            code={item.code}
                            title={item.title}
                            navigation={this.props.navigation}
                        />
                        <MenuNewsPilihanDesktop
                            code={item.code}
                            title={item.title}
                            navigation={this.props.navigation}
                        />
                    </>
                )
            }
        } else if ((item.type == 'youtube')) {
            if (dummy == 1) {

            } else {
                return (
                    <>
                        <MenuYoutubeDesktop
                            code={item.code}
                            title={item.title}
                            navigation={this.props.navigation}
                        />
                    </>
                )
            }
        } else if ((item.type == 'statistik')) {
            if (dummy == 1) {

            } else {
                return (
                    <MenuStatistikDesktop
                        code={item.code}
                        title={item.title}
                        navigation={this.props.navigation}
                    />
                )
            }
        }
    }

    navigateNewsDetail = (item, index) => {
        this.props.navigation.navigate("DesktopNewsDetail", item);
    }

    renderNews = ({ item, index, separators }) => {
        //console.log("Render News Index: " + index); 
        if (!item.hasOwnProperty("dummy")) {
            let image_height = this.state.card_width - (this.state.card_width * 0.2);
            return (
                <View
                    style={{
                        width: this.state.card_width,
                        height: this.state.card_height + 16,
                        borderRadius: 8,
                        marginBottom: 8,
                    }}
                >
                    <CardActionArea
                        style={{ height: (this.state.card_height + 100), borderRadius: 8 }}
                        onClick={() => { this.navigateNewsDetail(item, index) }}
                    >
                        <Image
                            style={{
                                borderRadius: 8,
                                width: this.state.card_width,
                                height: image_height,
                                position: "absolute",
                                top: 0,
                                left: 0,
                                right: 0
                            }}
                            resizeMode={"cover"}
                            source={{ uri: item.header_image }}
                        //onError={(error)=>{ this.onImageError(item, index)}}
                        />
                        <View style={{
                            position: 'absolute',
                            top: image_height + 16,
                            left: 8,
                            right: 8,
                            bottom: 8
                        }}>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={16}
                                fontWeight="700"
                            >
                                {item.title}
                            </Typography>
                            <Typography
                                fontFamily="Open Sans"
                                fontSize={12}
                                fontWeight="700"
                                color={sub_text}
                            >
                                {item.input_time}
                            </Typography>

                        </View>
                    </CardActionArea>

                </View>
            )
        } else {
            return (
                <View style={{ width: this.state.card_width, height: this.state.card_height + 100 }}>
                </View>
            )
        }


    }

    onLayoutContainerChange = (e) => {
        if (_first_run) {
            _first_run = false;
            let width = e.nativeEvent.layout.width;

            let card_width = (width / 4) - 16;
            let card_height = card_width + 50;
            this.setState({
                card_width: card_width,
                card_height: card_height,
                container_width: width
            })
        }
    }

    actionProfile = (e) => {
        this.setState({ selectedMenu: e })
    }

    //ANCHOR - RENDER RACK
    renderRack = ({ item, index, separators }) => {
        let view_width = (this.state.container_width / 2) - 16;
        let view_height = (view_width - (view_width * 0.7));
        let book_width = (view_width * 0.2) - 16;
        let book_height = book_width + 60;

        if (!item.hasOwnProperty("dummy")) {
            return (
                <View
                    style={{
                        width: view_width,
                        height: view_height,
                        borderRadius: 8,
                        marginBottom: 16
                    }}

                >

                    <CardActionArea
                        style={{ height: view_height, borderRadius: 8 }}
                    >
                        <View style={{ flexDirection: "row", justifyContent: "center" }}>
                            <Image
                                style={{
                                    borderRadius: 8,
                                    width: book_width,
                                    height: book_height,
                                    marginLeft: 16,
                                    marginRight: 16

                                }}
                                resizeMode={"cover"}
                                source={{ uri: item.cover }}
                                onError={(error) => { this.onImageError(item, index) }}
                            />
                            <View style={{ flex: 1, paddingRight: 16 }}>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={16}
                                    fontWeight="700"
                                    color={color_text}
                                >
                                    {item.title}
                                </Typography>

                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={14}
                                    fontWeight="700"
                                    color={sub_text}
                                >
                                    {item.author}
                                </Typography>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={14}
                                    fontWeight="500"
                                    style={{ marginTop: 16 }}
                                >
                                    Tgl.Pinjam : {item.loan_date_formatted}
                                </Typography>
                                <Typography
                                    fontFamily="Open Sans"
                                    fontSize={14}
                                    fontWeight="500"
                                >
                                    Tgl.Kembali : {item.returning_date_formatted}
                                </Typography>
                                <View style={{ position: 'absolute', flexDirection: "row-reverse", alignItems: "center", bottom: 0, left: 0, right: 16 }}>
                                    {
                                        //TODO: Handle Baca
                                    }
                                    <Button
                                        variant="contained"
                                        style={{
                                            textTransform: "none",
                                            borderRadius: 8, padding: 8,
                                            marginBottom: 0,
                                            minWidth: 97.61
                                        }}
                                        onClick={() => { this.handleReadFromRack(item) }}
                                    >
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={14}
                                            fontWeight="500"
                                        >
                                            Baca
                                        </Typography>
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        style={{
                                            textTransform: "none",
                                            borderRadius: 8, padding: 8, marginBottom: 0, marginRight: 8, minWidth: 97.61
                                        }}
                                        onClick={() => { this.handleBookReturn(item, index) }}
                                    >
                                        <Typography
                                            fontFamily="Open Sans"
                                            fontSize={14}
                                            fontWeight="500"
                                        >
                                            Kembalikan
                                        </Typography>
                                    </Button>
                                </View>
                            </View>
                        </View>
                    </CardActionArea>

                </View>
            )
        } else {
            return (
                <View
                    style={{
                        width: view_width,
                        height: view_height,
                        borderRadius: 8,
                        marginBottom: 16
                    }}

                >
                </View>
            )
        }

    }
    onImageError = (item, index) => {
        if (_is_mounted) {
            let data = [...this.state.rack_data];
            let obj = { ...data[index] };
            obj.cover = "https://bintangpusnas.perpusnas.go.id/images/cover/placeholder-no-img.png";
            data[index] = obj;
            this.setState({
                rack_data: data
            })
        }
    }

    handleBookReturn = async (item, index) => {

        this.setState({
            showLoadingDialog: true,
            loadingDialogMessage: "Proses Pengembalian"
        })
        let formData = new FormData();
        formData.append("content_code", item.content_code);

        const response = await executePost(bookReturn, formData);
        this.setState({
            showLoadingDialog: false
        })

        if (response.code == code_ok) {
            let data = [];
            //let data = [...this.state.rack_data]; 
            //cleanup from dummies 
            this.state.rack_data.map((i, x) => {
                if (!i.hasOwnProperty("dummy")) {
                    data.push(i);
                }
            });

            data.splice(index, 1);

            if (data.length > 0) {
                let length = data.length;
                let mod = length % 2;
                if (mod > 0) {
                    let add_factor = 2 - mod;
                    for (let i = 0; i < add_factor; i++) {
                        data.push({ dummy: i })
                    }
                }
                this.setState({
                    rack_data: data
                })
            } else {
                this.setState({
                    rack_data: []
                })
            }
        } else {
            if (response.code == code_auth_error) {
                window.postMessage({ id: "sign_out" })
            } else {
                this.setState({
                    showLoading: false,
                    showAlert: true,
                    alertMessage: response.msg
                })
            }
        }
    }
    //ANCHOR - HANDLE READ FROM RACK
    handleReadFromRack = (item) => {
        let content_code = item.content_code;
        this.readFromRack(content_code);
    }

    //ANCHOR - AUTHENTICATE READ FROM RACK 
    readFromRack = async (code) => {
        let formData = new FormData();
        formData.append("code", code);

        this.setState({
            showLoadingDialog: true,
            loadingDialogMessage: "Memeriksa Peminjaman"
        })

        const response = await executePost(readBorrowedContent, formData);
        console.log(JSON.stringify(response));
        if (response.code == code_ok) {
            this.setState({
                showLoadingDialog: false
            })
            let data = response.data;
            data.mode = "borrow";
            this.props.navigation.navigate("ReaderDesktop", data);

        } else {
            if (response.code == code_empty) {
                this.setState({
                    showAlert: true,
                    showLoadingDialog: false,
                    alertMessage: response.msg
                })
            } else {
                //TODO - Force Logout 
                window.postMessage({ id: "sign_out" })
            }
        }
    }


    render() {
        return (
            <View style={{ flex: 1, flexDirection: "row" }}>
                <View style={styles.mainMenuContainer}>
                    <Header
                        fullname={this.state.fullname}
                        action={this.actionProfile}
                    />
                    <View style={styles.mainContainer}
                        onLayout={this.onLayoutContainerChange}
                    >
                        {
                            this.state.showLoading &&

                            <LoadingPage
                                message={"Memuat data"}
                            />
                        }
                        {
                            (!this.state.showLoading && this.state.selectedMenu == "home") &&
                            <FlatList
                                showsVerticalScrollIndicator={false}
                                listKey={"homepage_list"}
                                keyExtractor={(i, x) => "code_" + i.code}
                                data={this.state.homepage_data}
                                renderItem={this.renderItem}
                            />
                        }
                        {
                            (!this.state.showLoading && this.state.selectedMenu == "news" && this.state.news_data.length > 0) &&
                            <FlatList
                                style={{ marginLeft: 16, marginRight: 16, marginTop: 16 }}
                                showsVerticalScrollIndicator={false}
                                listKey={"news_list"}
                                keyExtractor={(i, x) => "news_index_" + x}
                                data={this.state.news_data}
                                numColumns={4}
                                columnWrapperStyle={{ justifyContent: "space-between" }}
                                renderItem={this.renderNews}
                            />
                        }
                        {
                            (!this.state.showLoading && this.state.selectedMenu == "news" && this.state.news_data.length == 0) &&
                            <View style={{ flex: 1, alignItems: 'center', justifyContent: "center" }}>
                                <EmptySearchPage
                                    title={"Tidak Ditemukan"}
                                    message={"Belum ada berita dihalaman ini"}
                                />
                            </View>
                        }
                        {
                            //ANCHOR - MENU RACK
                            (!this.state.showLoading && this.state.selectedMenu == "rack" && this.state.rack_data.length > 0) &&
                            <FlatList
                                style={{ marginLeft: 16, marginRight: 16, marginTop: 16 }}
                                showsVerticalScrollIndicator={false}
                                keyExtractor={(i, x) => "rack_index_" + x}
                                data={this.state.rack_data}
                                numColumns={2}
                                columnWrapperStyle={{ justifyContent: "space-between" }}
                                renderItem={this.renderRack}
                            />
                        }
                        {
                            (!this.state.showLoading && this.state.selectedMenu == "rack" && this.state.rack_data.length == 0) &&
                            <View style={{ flex: 1, alignItems: 'center', justifyContent: "center" }}>
                                <EmptySearchPage
                                    title={"Tidak Ditemukan"}
                                    message={"Belum ada buku yang anda pinjam"}
                                />
                            </View>
                        }
                        {
                            //ANCHOR - MENU PROFILE
                            (!this.state.showLoading && this.state.selectedMenu == "profile") &&
                            <DesktopProfile
                                data={this.props.route.params}
                                navigation={this.props.navigation}
                            />
                        }
                        {
                            //ANCHOR - MENU SEARCH
                            (this.state.selectedMenu == "search") &&
                            <DesktopSearchPage
                                ref={this.searchRef}
                                navigation={this.props.navigation}
                                keyword={this.state.keyword}
                            />
                        }


                        <WarningDialog
                            open={this.state.showAlert}
                            message={this.state.alertMessage}
                            caption={"OK"}
                            onClick={this.handleWarningDialogClick}
                        />
                        <LoadingDialog
                            open={this.state.showLoadingDialog}
                            message={this.state.loadingDialogMessage}
                        />
                    </View>
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    leftMenuContainer: {
        flex: 0.06,
        paddingTop: 16,
        borderColor: "#E8E8F2",
        borderRightWidth: 0.5,
        backgroundColor: "white",
        alignItems: "center"
    },
    mainMenuContainer: {
        flex: 1,
        backgroundColor: "white"
    },
    mainToolbar: {
        flex: 0.07,
        borderColor: "#E8E8F2",
        borderBottomWidth: 0.5,
        backgroundColor: "white",
        alignItems: "center",
        flexDirection: "row",
        paddingLeft: 48,
        paddingRight: 48

    },
    mainContainer: {
        flex: 0.93
    },
    menuNavigation: {
        marginTop: 24
    },
    searchBar: {
        backgroundColor: "#F9FAFC",
        borderRadius: 8,
        borderWidth: 0.2,
        padding: 8,
        flexDirection: "row",
        flex: 0.3
    },
    searchInput: {
        fontFamily: "Open Sans",
        fontWeight: "400",
        color: color_text,
        fontSize: 14,
        flex: 0.95,
        backgroundColor: "#F9FAFC",
        outlineColor: "#F9FAFC",
        marginLeft: 18
    },
    userBar: {
        flex: 1,
        flexDirection: "row-reverse",
        alignItems: "center"
    }
})


export default Main; 