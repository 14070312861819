import React, { Component } from 'react';
import Svg, {
    G,
    Path,
    Rect,
    Defs,
    Pattern,
    Use,
    LinearGradient,
    Stop,
    ClipPath,
    Circle,
    Image
} from 'react-native-svg';

class IconMusikKeluargaDesktop extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Svg
                xmlns="http://www.w3.org/2000/svg"
                width={107}
                height={179}
                fill="none"
                {...this.props}
            >
                <Circle cx={44.879} cy={3.926} r={57.973} fill="#fff" fillOpacity={0.1} />
                <Circle
                    cx={23.412}
                    cy={23.412}
                    r={23.412}
                    fill="#fff"
                    fillOpacity={0.1}
                    transform="matrix(1 0 0 -1 32.615 201.139)"
                />
                <Rect
                    width={60}
                    height={60}
                    x={13.335}
                    y={13.631}
                    fill="#fff"
                    rx={9.787}
                />
                <Path
                    fill="#12D7E4"
                    fillRule="evenodd"
                    d="M37.797 27.44c0-1.872 1.6-3.754 3.765-3.203 4.888 1.244 8.504 5.674 8.504 10.95a11.27 11.27 0 0 1-.822 4.238.968.968 0 0 1-1.796 0c-.713-1.762-1.94-3.572-3.481-4.93-1.273-1.122-2.718-1.9-4.232-2.137v11.87a7.425 7.425 0 1 1-1.938-5.002V27.439Z"
                    clipRule="evenodd"
                />
            </Svg>
        )
    }
}


export default IconMusikKeluargaDesktop;