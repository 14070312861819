import React, { Component } from 'react';
import Svg, {
    G,
    Path,
    Rect,
    Defs,
    Pattern,
    Use,
    LinearGradient,
    Stop,
    ClipPath,
    Circle,
    Image
} from 'react-native-svg';

class IconMenikahDesktop extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Svg
                xmlns="http://www.w3.org/2000/svg"
                width={104}
                height={179}
                fill="none"
                {...this.props}
            >
                <Circle cx={45.323} cy={3.813} r={57.973} fill="#fff" fillOpacity={0.1} />
                <Circle
                    cx={23.412}
                    cy={23.412}
                    r={23.412}
                    fill="#fff"
                    fillOpacity={0.1}
                    transform="matrix(1 0 0 -1 33.06 201.025)"
                />
                <Rect
                    width={60}
                    height={60}
                    x={12.148}
                    y={12.702}
                    fill="#fff"
                    rx={9.787}
                />
                <G fill="#DC4776" clipPath="url(#a)">
                    <Path d="M51.794 39.518c-.24-4.07-4.323-3.95-4.323-3.95s-3.959 3.71-8.164 0c0 0-.243-.239-1.083 0-.836.239-1.317.76-1.912.683h-.01c-.596.076-1.076-.444-1.912-.682-.84-.24-1.083 0-1.083 0-4.205 3.71-8.164 0-8.164 0s-4.083-.12-4.323 3.949c-.24 4.068 4 12.8 15.487 12.565 11.487.236 15.727-8.498 15.487-12.565Zm-8.776 3.425c-.101 1.158-.6 2.242-1.362 3.123a17.81 17.81 0 0 1-5.345 4.2v.018l-.017-.008-.017.008v-.018a17.854 17.854 0 0 1-5.345-4.2c-.762-.88-1.261-1.965-1.362-3.123-.106-1.212.195-2.648 1.816-3.545 3.056-1.692 4.725 1.08 4.907 1.405.18-.324 1.85-3.097 4.907-1.405 1.62.897 1.92 2.333 1.815 3.545h.003ZM28.985 34.192c2.951 0 5.344-2.384 5.344-5.325 0-2.941-2.393-5.326-5.344-5.326-2.952 0-5.344 2.385-5.344 5.326 0 2.94 2.392 5.325 5.344 5.325ZM50.105 32.77c-.878-.625-1.8-1.807-2.034-2.117.2-.558.31-1.16.31-1.787 0-2.942-2.392-5.326-5.344-5.326-2.952 0-5.344 2.384-5.344 5.326 0 .801.179 1.561.497 2.243-.406.493-1.05 1.216-1.673 1.659-.883.628 2.49 1.267 4.017.802a5.34 5.34 0 0 0 2.504.62c1 0 1.933-.274 2.733-.75 1.295.695 5.288.004 4.337-.673l-.003.002Z" />
                </G>
                <Defs>
                    <ClipPath id="a">
                        <Path fill="#fff" d="M20.81 23.541h30.994v28.546H20.81z" />
                    </ClipPath>
                </Defs>
            </Svg>
        )
    }
}


export default IconMenikahDesktop;