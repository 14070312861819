import React, { Component } from 'react';
import Svg, {
    G,
    Path,
    Rect,
    Defs,
    Pattern,
    Use,
    LinearGradient,
    Stop,
    ClipPath,
    Circle,
    Image
} from 'react-native-svg';

class IconMusikKeluarga extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Svg
                xmlns="http://www.w3.org/2000/svg"
                width={107}
                height={135}
                fill="none"
                {...this.props}
            >
                <Circle cx={47.033} cy={4.236} r={60.035} fill="#fff" fillOpacity={0.1} />
                <Circle
                    cx={24.245}
                    cy={24.245}
                    r={24.245}
                    fill="#fff"
                    fillOpacity={0.1}
                    transform="matrix(1 0 0 -1 34.333 157.787)"
                />
                <Rect width={50.799} height={50.799} x={7.777} y={7.7} fill="#fff" rx={7} />
                <Path
                    fill="#12D7E4"
                    fillRule="evenodd"
                    d="M30.955 22.627c0-1.45 1.24-2.907 2.915-2.48a8.753 8.753 0 0 1 5.949 11.761.75.75 0 0 1-1.39 0 10.465 10.465 0 0 0-2.697-3.818c-.986-.869-2.104-1.47-3.277-1.654v9.191a5.75 5.75 0 1 1-1.5-3.873v-9.127Z"
                    clipRule="evenodd"
                />
            </Svg>
        )
    }
}


export default IconMusikKeluarga;