import React, { Component } from 'react';
import Svg, {
    G,
    Path,
    Rect,
    Defs,
    Pattern,
    Use,
    LinearGradient,
    Stop,
    ClipPath,
    Circle,
    Image
} from 'react-native-svg';

class IconGoldenAge extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Svg
                xmlns="http://www.w3.org/2000/svg"
                width={107}
                height={135}
                fill="none"
                {...this.props}
            >
                <Circle cx={46.72} cy={3.921} r={60.035} fill="#fff" fillOpacity={0.1} />
                <Circle
                    cx={24.245}
                    cy={24.245}
                    r={24.245}
                    fill="#fff"
                    fillOpacity={0.1}
                    transform="matrix(1 0 0 -1 34.02 157.472)"
                />
                <Rect
                    width={50.799}
                    height={50.799}
                    x={7.465}
                    y={7.385}
                    fill="#fff"
                    rx={7}
                />
                <G fill="#F4AF33" clipPath="url(#a)">
                    <Path d="M39.843 26.533a1.746 1.746 0 0 0-.992.462c-4.035 3.635-8.233 4.5-8.458 4.545-.226-.045-4.424-.91-8.459-4.544a1.746 1.746 0 0 0-.992-.463c-.724-.07-1.707.319-1.527 3.035.282 4.22 6.413 13.082 10.966 13.738h.023c4.553-.662 10.684-9.523 10.966-13.744.18-2.716-.803-3.105-1.527-3.034v.005Z" />
                    <Path d="M30.393 28.3a5.494 5.494 0 1 0 0-10.988 5.494 5.494 0 0 0 0 10.988Z" />
                </G>
                <Defs>
                    <ClipPath id="a">
                        <Path fill="#fff" d="M19.393 17.312h22v26h-22z" />
                    </ClipPath>
                </Defs>
            </Svg>
        )
    }
}


export default IconGoldenAge;