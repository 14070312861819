import React, { Component } from 'react';
import Svg, {
    G,
    Path,
    Rect,
    Defs,
    Pattern,
    Use,
    LinearGradient,
    Stop,
    ClipPath,
    Circle,
    Image
} from 'react-native-svg';

class IconGameEdukasi extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Svg
                xmlns="http://www.w3.org/2000/svg"
                width={107}
                height={135}
                fill="none"
                {...this.props}
            >
                <Circle cx={46.346} cy={4.236} r={60.035} fill="#fff" fillOpacity={0.1} />
                <Circle
                    cx={24.245}
                    cy={24.245}
                    r={24.245}
                    fill="#fff"
                    fillOpacity={0.1}
                    transform="matrix(1 0 0 -1 33.645 157.787)"
                />
                <Rect width={50.799} height={50.799} x={7.091} y={7.7} fill="#fff" rx={7} />
                <Path
                    fill="#0194F3"
                    fillRule="evenodd"
                    d="M30.018 20.064c.45 0 .813.364.813.813v1.882c1.55.02 3.1.103 4.646.246l.36.034a4.83 4.83 0 0 1 4.384 4.728l.013.773a46.244 46.244 0 0 1-.723 8.969 2.827 2.827 0 0 1-2.783 2.326h-.239a3.113 3.113 0 0 1-2.846-1.85l-1.149-2.591c-.952-2.147-4-2.147-4.951 0l-1.15 2.59a3.113 3.113 0 0 1-2.845 1.851h-.24c-1.368 0-2.54-.98-2.782-2.326a46.252 46.252 0 0 1-.724-8.97l.014-.772a4.83 4.83 0 0 1 4.384-4.728l.36-.034a59.189 59.189 0 0 1 4.646-.246v-1.882c0-.449.364-.813.812-.813Zm5.417 6.23a1.083 1.083 0 1 0-2.167 0v.27a1.083 1.083 0 0 0 2.167 0v-.27Zm-9.75-.813c.449 0 .813.364.813.813v1.354h1.354a.813.813 0 0 1 0 1.625h-1.354v1.354a.813.813 0 0 1-1.625 0v-1.354h-1.355a.813.813 0 0 1 0-1.625h1.355v-1.354c0-.45.363-.813.812-.813Zm8.667 3.792c.598 0 1.083.485 1.083 1.083v.271a1.083 1.083 0 1 1-2.167 0v-.27c0-.6.485-1.084 1.084-1.084Zm2.166.27a1.083 1.083 0 1 0 0-2.166h-.27a1.083 1.083 0 1 0 0 2.167h.27ZM33.54 28.46c0 .599-.485 1.084-1.083 1.084h-.27a1.083 1.083 0 1 1 0-2.167h.27c.598 0 1.083.485 1.083 1.083Z"
                    clipRule="evenodd"
                />
            </Svg>
        )
    }
}


export default IconGameEdukasi;