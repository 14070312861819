import React, { Component } from 'react';
import Svg, {
    G,
    Path,
    Rect,
    Defs,
    Pattern,
    Use,
    LinearGradient,
    Stop,
    ClipPath,
    Circle,
    Image
} from 'react-native-svg';

class IconGameEdukasiDesktop extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Svg
                xmlns="http://www.w3.org/2000/svg"
                width={107}
                height={179}
                fill="none"
                {...this.props}
            >
                <Circle cx={45.546} cy={3.926} r={57.973} fill="#fff" fillOpacity={0.1} />
                <Circle
                    cx={23.412}
                    cy={23.412}
                    r={23.412}
                    fill="#fff"
                    fillOpacity={0.1}
                    transform="matrix(1 0 0 -1 33.282 201.139)"
                />
                <Rect
                    width={60}
                    height={60}
                    x={13.185}
                    y={13.631}
                    fill="#fff"
                    rx={9.787}
                />
                <Path
                    fill="#0194F3"
                    fillRule="evenodd"
                    d="M37.896 24.758c.577 0 1.045.468 1.045 1.045v2.42c1.994.027 3.988.133 5.976.317l.462.043a6.213 6.213 0 0 1 5.639 6.081l.017.994a59.481 59.481 0 0 1-.93 11.536 3.636 3.636 0 0 1-3.58 2.992h-.307a4.004 4.004 0 0 1-3.66-2.38l-1.478-3.332c-1.225-2.762-5.144-2.762-6.368 0l-1.478 3.332a4.004 4.004 0 0 1-3.66 2.38h-.309a3.636 3.636 0 0 1-3.578-2.992 59.481 59.481 0 0 1-.93-11.536l.016-.994a6.213 6.213 0 0 1 5.64-6.08l.462-.044a76.164 76.164 0 0 1 5.976-.317v-2.42c0-.577.468-1.045 1.045-1.045Zm6.966 8.012a1.393 1.393 0 1 0-2.786 0v.348a1.393 1.393 0 0 0 2.786 0v-.348Zm-12.54-1.045c.578 0 1.045.468 1.045 1.045v1.741h1.742a1.045 1.045 0 1 1 0 2.09h-1.742v1.742a1.045 1.045 0 1 1-2.09 0V36.6h-1.741a1.045 1.045 0 0 1 0-2.09h1.741V32.77c0-.578.468-1.045 1.045-1.045ZM43.47 36.6c.77 0 1.393.624 1.393 1.394v.348a1.393 1.393 0 1 1-2.786 0v-.348c0-.77.623-1.394 1.393-1.394Zm2.787.349a1.393 1.393 0 1 0 0-2.787h-.349a1.393 1.393 0 1 0 0 2.787h.349Zm-3.832-1.394c0 .77-.624 1.394-1.393 1.394h-.349a1.393 1.393 0 1 1 0-2.787h.349c.77 0 1.393.624 1.393 1.393Z"
                    clipRule="evenodd"
                />
            </Svg>
        )
    }
}


export default IconGameEdukasiDesktop;