import React, { Component } from 'react';
import Svg, {
    G,
    Path,
    Rect,
    Defs,
    Pattern,
    Use,
    LinearGradient,
    Stop,
    ClipPath,
    Circle,
    Image
} from 'react-native-svg';

class IconPerpusnasPress extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <Svg
                xmlns="http://www.w3.org/2000/svg"
                width={107}
                height={135}
                fill="none"
                {...this.props}
            >
                <Circle cx={46.346} cy={3.706} r={60.035} fill="#fff" fillOpacity={0.1} />
                <Circle
                    cx={24.245}
                    cy={24.245}
                    r={24.245}
                    fill="#fff"
                    fillOpacity={0.1}
                    transform="matrix(1 0 0 -1 33.645 134.166)"
                />
                <Rect
                    width={50.799}
                    height={50.799}
                    x={7.091}
                    y={7.169}
                    fill="#fff"
                    rx={7}
                />
                <Path
                    fill="#A3D513"
                    d="M32.495 17.56c.446.677.876 1.365 1.318 2.045.808 1.255 1.65 2.496 2.313 3.836.43.872.764 1.801.878 2.768a7.05 7.05 0 0 1-.04 1.971l.064-.026c-.17.12-.271.305-.395.465-1.283 1.719-2.559 3.442-3.845 5.159a5.47 5.47 0 0 0-.453-1.402c-.368-.762-.893-1.437-1.427-2.092-.617-.749-1.254-1.481-1.854-2.243 1.146-3.494 2.293-6.988 3.441-10.481Z"
                />
                <Path
                    fill="#A3D513"
                    d="M37.03 28.154c1.08-.459 2.267-.66 3.44-.632 1.463.02 2.897.34 4.32.644 1.28.294 2.574.535 3.864.784-3.082 2.2-6.173 4.39-9.256 6.59-.881-.301-1.74-.658-2.61-.988-1.274-.484-2.634-.91-4.016-.75l.018-.025c1.285-1.716 2.562-3.44 3.844-5.158.124-.16.225-.346.395-.465ZM16.327 28.637c2.761-.002 5.522.008 8.284.008 1.151-.003 2.303.014 3.456.003.422.516.84 1.037 1.273 1.545.503.6 1.015 1.193 1.459 1.837.395.58.73 1.222.84 1.92.071.401.05.811.075 1.217.006.088-.055.156-.104.222-1.726 2.3-3.431 4.616-5.146 6.925-1.22 1.642-2.437 3.287-3.655 4.93-.054.077-.106.158-.181.216.017-.139.076-.267.117-.4 1.242-3.734 2.485-7.467 3.726-11.202l-7.92-5.634c-.74-.53-1.488-1.051-2.224-1.587Z"
                />
                <Path
                    fill="#A3D513"
                    d="M31.715 35.167c.488-.116.977-.256 1.482-.259.886-.043 1.755.192 2.584.475 1.154.405 2.275.897 3.435 1.284 1.204 3.637 2.419 7.27 3.624 10.906-.634-.431-1.248-.893-1.875-1.335-2.751-1.977-5.509-3.945-8.257-5.926-3.376 2.423-6.753 4.844-10.13 7.265l.031-.142.02.025c.075-.058.127-.14.18-.216 1.219-1.643 2.435-3.288 3.656-4.93 1.715-2.309 3.42-4.624 5.146-6.925.048-.066.11-.134.104-.222Z"
                />
            </Svg>
        )
    }
}


export default IconPerpusnasPress;